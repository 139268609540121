import logo from '../Assets/Images/logo.webp';
import sliderOne from '../Assets/Images/slider/slider-one.webp';
import sliderTwo from '../Assets/Images/slider/slider-two.webp';
import sliderThree from '../Assets/Images/slider/slider-three.webp';
import general from '../Assets/Images/categories/generalwellness.webp';
import sexual from '../Assets/Images/categories/sexual_wellness.webp';
import intimicy from '../Assets/Images/categories/intimate_wellness.webp';
import toi from '../Assets/Images/Feature/TOI_one.webp';
import cnbc from '../Assets/Images/Feature/CNBC_Two.webp';
import et from '../Assets/Images/Feature/ET_NOW_Three.avif';
import ndtv from '../Assets/Images/Feature/NDTV_Four.avif';
import ht from '../Assets/Images/Feature/HT_CITY_five.avif';
import miss from '../Assets/Images/Feature/MISS_MALINI_six.webp';
import diva from '../Assets/Images/Feature/DIVA_seven.webp';
import femina from '../Assets/Images/Feature/FEMINA_eight.avif';
import cosmo from '../Assets/Images/Feature/COSMO_nine.avif';
import vogue from '../Assets/Images/Feature/VOGUE_ten.webp';
import testimonialOne from '../Assets/Images/testimonial/one.webp';
import testimonialTwo from '../Assets/Images/testimonial/two.webp';
import testimonialThree from '../Assets/Images/testimonial/three.webp';
import testimonialFour from '../Assets/Images/testimonial/four.webp';
import testimonialFive from '../Assets/Images/testimonial/five.webp';
import testimonialSix from '../Assets/Images/testimonial/six.webp';
import aboutBanner from '../Assets/Images/banners/about-banner.webp';
import generalBanner from '../Assets/Images/banners/general-wellness.webp';
import sexualBanner from '../Assets/Images/banners/sexual-wellness.webp';
import intimateBanner from '../Assets/Images/banners/Inimate-menstrual-wellness.webp';
import shopBanner from '../Assets/Images/banners/shop-banner.webp';
import faqBanner from '../Assets/Images/banners/faq-banner.webp';
import contactBanner from '../Assets/Images/banners/contact-banner.webp';
import benefits from '../Assets/Images/banners/benifits-banner.webp';
import aboutLeft from '../Assets/Images/aboutPage/about-left-img.webp';
import aboutRight from '../Assets/Images/aboutPage/about-right.webp';
import Profile from '../Assets/Images/aboutPage/profile.svg';
import mission from '../Assets/Images/aboutPage/mission.avif';
import vision from '../Assets/Images/aboutPage/vision.avif';
import purpose from '../Assets/Images/aboutPage/purpose.avif';
import values from '../Assets/Images/aboutPage/values-new.webp';
import milestone from '../Assets/Images/aboutPage/milestone.webp';
import generalThumb from '../Assets/Images/generalProducts/niramaTablet/nirma_tablet_thumb.webp';
import generalTwo from '../Assets/Images/generalProducts/niramaTablet/nirama_two.webp';
import generalThree from '../Assets/Images/generalProducts/niramaTablet/nirama_three.webp';
import generalFour from '../Assets/Images/generalProducts/niramaTablet/nirama_four.webp';
import generalFive from '../Assets/Images/generalProducts/niramaTablet/nirama_five.webp';
import niramaCreamthumb from '../Assets/Images/generalProducts/niramaCream/nirama_cream_thumb.webp';
import niramaCreamTwo from '../Assets/Images/generalProducts/niramaCream/nirama_cream_two.webp';
import niramaCreamThree from '../Assets/Images/generalProducts/niramaCream/nirma_cream_three.webp';
import niramaCreamFour from '../Assets/Images/generalProducts/niramaCream/nirama_cream_four.webp';
import niramaCreamFive from '../Assets/Images/generalProducts/niramaCream/nirama_cream_five.webp';
import ReliefThumb from '../Assets/Images/generalProducts/nirmaReliefTablet/relief_thumb.webp';
import ReliefTwo from '../Assets/Images/generalProducts/nirmaReliefTablet/relief_two.webp';
import ReliefThree from '../Assets/Images/generalProducts/nirmaReliefTablet/relief_three.webp';
import ReliefFour from '../Assets/Images/generalProducts/nirmaReliefTablet/relief_four.webp';
import ReliefFive from '../Assets/Images/generalProducts/nirmaReliefTablet/relief_five.webp';
import niramaOilthumb from '../Assets/Images/generalProducts/niramaBodyOil/body_oil_thumb.webp';
import niramaOilTwo from '../Assets/Images/generalProducts/niramaBodyOil/oil_two.webp';
import niramaOilThree from '../Assets/Images/generalProducts/niramaBodyOil/oil_three.webp';
import niramaOilFour from '../Assets/Images/generalProducts/niramaBodyOil/oil_four.webp';
import niramaOilFive from '../Assets/Images/generalProducts/niramaBodyOil/oil_five.webp';
import jointThumb from '../Assets/Images/generalProducts/niramaJoint/joint_thumb.webp';
import jointTwo from '../Assets/Images/generalProducts/niramaJoint/joint_two.webp';
import jointThree from '../Assets/Images/generalProducts/niramaJoint/joint_three.webp';
import jointFour from '../Assets/Images/generalProducts/niramaJoint/joint_four.webp';
import jointFive from '../Assets/Images/generalProducts/niramaJoint/joint_five.webp';
import stimulatethumb from '../Assets/Images/sexualProducts/stimulate/stimulate_thumb.webp';
import stimulateTwo from '../Assets/Images/sexualProducts/stimulate/stimulate_two.webp';
import stimulateThree from '../Assets/Images/sexualProducts/stimulate/stimulate_three.webp';
import stimulateFour from '../Assets/Images/sexualProducts/stimulate/stimulate_four.webp';
import stimulateFive from '../Assets/Images/sexualProducts/stimulate/stimulate_five.webp';
import boosterTwentythumb from '../Assets/Images/sexualProducts/boosterTabletTwenty/booster_thumb.webp';
import boosterTwentyTwo from '../Assets/Images/sexualProducts/boosterTabletTwenty/booster_two.webp';
import boosterTwentyThree from '../Assets/Images/sexualProducts/boosterTabletTwenty/booster_three.webp';
import boosterTwentyFour from '../Assets/Images/sexualProducts/boosterTabletTwenty/booster_four.webp';
import boosterTwentyFive from '../Assets/Images/sexualProducts/boosterTabletTwenty/booster_five.webp';
import boosterSixtythumb from '../Assets/Images/sexualProducts/boosterTabletSixty/booster_thumb.webp';
import boosterSixtyTwo from '../Assets/Images/sexualProducts/boosterTabletSixty/booster_two.webp';
import boosterSixtyThree from '../Assets/Images/sexualProducts/boosterTabletSixty/booster_three.webp';
import boosterSixtyFour from '../Assets/Images/sexualProducts/boosterTabletSixty/booster_four.webp';
import boosterSixtyFive from '../Assets/Images/sexualProducts/boosterTabletSixty/booster_five.webp';
import lubricantthumb from '../Assets/Images/sexualProducts/lubricant/lub_thumb.webp';
import lubricantTwo from '../Assets/Images/sexualProducts/lubricant/lub_two.webp';
import lubricantThree from '../Assets/Images/sexualProducts/lubricant/lub_three.webp';
import lubricantFour from '../Assets/Images/sexualProducts/lubricant/lub_four.webp';
import lubricantFive from '../Assets/Images/sexualProducts/lubricant/lub_thumb.webp';
import daliaCreamthumb from '../Assets/Images/intimate/massageCream/dalia_cream_thumb.webp';
import daliaCreamTwo from '../Assets/Images/intimate/massageCream/dalia_two.webp';
import daliaCreamThree from '../Assets/Images/intimate/massageCream/dalia_three.webp';
import daliaCreamFour from '../Assets/Images/intimate/massageCream/dalia_four.webp';
import daliaCreamFive from '../Assets/Images/intimate/massageCream/dalia_five.webp';
import backCreamthumb from '../Assets/Images/intimate/backCream/back_thumb.webp';
import backCreamTwo from '../Assets/Images/intimate/backCream/back_two.webp';
import backCreamThree from '../Assets/Images/intimate/backCream/back_three.webp';
import backCreamFour from '../Assets/Images/intimate/backCream/back_four.webp';
import backCreamFive from '../Assets/Images/intimate/backCream/back_five.webp';
import reliefOilthumb from '../Assets/Images/intimate/rosaOil/rosa_thumb.webp';
import reliefOilTwo from '../Assets/Images/intimate/rosaOil/rosa_two.webp';
import reliefOilThree from '../Assets/Images/intimate/rosaOil/rosa_three.webp';
import reliefOilFour from '../Assets/Images/intimate/rosaOil/rosa_four.webp';
import reliefOilFive from '../Assets/Images/intimate/rosaOil/rosa_five.webp';
import hyginethumb from '../Assets/Images/intimate/hygiene/hygiene_thumb.webp';
import hygineTwo from '../Assets/Images/intimate/hygiene/hygiene_two.webp';
import hygineThree from '../Assets/Images/intimate/hygiene/hygiene_three.webp';
import hygineFour from '../Assets/Images/intimate/hygiene/hygiene_four.webp';
import hygineFive from '../Assets/Images/intimate/hygiene/hygiene_five.webp';
import rosathumb from '../Assets/Images/rosaproduct/rosa_thumb.webp';
import rosaTwo from '../Assets/Images/rosaproduct/rosa_two.webp';
import rosaThree from '../Assets/Images/rosaproduct/rosa_three.webp';
import rosaFour from '../Assets/Images/rosaproduct/rosa_four.webp';
import rosaFive from '../Assets/Images/rosaproduct/rosa_five.webp';

const Assets = {
    logo: logo,
    sliderOne: sliderOne,
    sliderTwo: sliderTwo,
    sliderThree: sliderThree,
    general: general,
    sexual: sexual,
    intimicy: intimicy,
    toi: toi,
    cnbc: cnbc,
    et: et,
    ndtv: ndtv,
    ht: ht,
    miss: miss,
    diva: diva,
    femina: femina,
    cosmo: cosmo,
    vogue: vogue,
    testimonialOne: testimonialOne,
    testimonialTwo: testimonialTwo,
    testimonialThree: testimonialThree,
    testimonialFour: testimonialFour,
    testimonialFive: testimonialFive,
    testimonialSix: testimonialSix,
    aboutBanner: aboutBanner,
    generalBanner: generalBanner,
    sexualBanner: sexualBanner,
    intimateBanner: intimateBanner,
    faqBanner: faqBanner,
    contactBanner: contactBanner,
    shopBanner: shopBanner,
    benefits: benefits,
    aboutLeft: aboutLeft,
    aboutRight: aboutRight,
    Profile: Profile,
    mission: mission,
    vision: vision,
    purpose: purpose,
    values: values,
    milestone: milestone,
    generalThumb: generalThumb,
    generalTwo: generalTwo,
    generalThree: generalThree,
    generalFour: generalFour,
    generalFive: generalFive,
    niramaCreamthumb: niramaCreamthumb,
    niramaCreamTwo: niramaCreamTwo,
    niramaCreamThree: niramaCreamThree,
    niramaCreamFour: niramaCreamFour,
    niramaCreamFive: niramaCreamFive,
    ReliefThumb: ReliefThumb,
    ReliefTwo: ReliefTwo,
    ReliefThree: ReliefThree,
    ReliefFour: ReliefFour,
    ReliefFive: ReliefFive,
    niramaOilthumb: niramaOilthumb,
    niramaOilTwo: niramaOilTwo,
    niramaOilThree: niramaOilThree,
    niramaOilFour: niramaOilFour,
    niramaOilFive: niramaOilFive,
    jointThumb: jointThumb,
    jointTwo: jointTwo,
    jointThree: jointThree,
    jointFour: jointFour,
    jointFive: jointFive,
    stimulatethumb: stimulatethumb,
    stimulateTwo: stimulateTwo,
    stimulateThree: stimulateThree,
    stimulateFour: stimulateFour,
    stimulateFive: stimulateFive,
    boosterTwentythumb: boosterTwentythumb,
    boosterTwentyTwo: boosterTwentyTwo,
    boosterTwentyThree: boosterTwentyThree,
    boosterTwentyFour: boosterTwentyFour,
    boosterTwentyFive: boosterTwentyFive,
    boosterSixtythumb: boosterSixtythumb,
    boosterSixtyTwo: boosterSixtyTwo,
    boosterSixtyThree: boosterSixtyThree,
    boosterSixtyFour: boosterSixtyFour,
    boosterSixtyFive: boosterSixtyFive,
    lubricantthumb: lubricantthumb,
    lubricantTwo: lubricantTwo,
    lubricantThree: lubricantThree,
    lubricantFour: lubricantFour,
    lubricantFive: lubricantFive,
    daliaCreamthumb: daliaCreamthumb,
    daliaCreamTwo: daliaCreamTwo,
    daliaCreamThree: daliaCreamThree,
    daliaCreamFour: daliaCreamFour,
    daliaCreamFive: daliaCreamFive,
    backCreamthumb: backCreamthumb,
    backCreamTwo: backCreamTwo,
    backCreamThree: backCreamThree,
    backCreamFour: backCreamFour,
    backCreamFive: backCreamFive,
    reliefOilthumb: reliefOilthumb,
    reliefOilTwo: reliefOilTwo,
    reliefOilThree: reliefOilThree,
    reliefOilFour: reliefOilFour,
    reliefOilFive: reliefOilFive,
    hyginethumb: hyginethumb,
    hygineTwo: hygineTwo,
    hygineThree: hygineThree,
    hygineFour: hygineFour,
    hygineFive: hygineFive,
    rosathumb: rosathumb,
    rosaTwo: rosaTwo,
    rosaThree: rosaThree,
    rosaFour: rosaFour,
    rosaFive: rosaFive,
}

export default Assets;