import React from 'react';

const HomePageView = React.lazy(() => import('../Views/HomePageView'));
const AboutPageView = React.lazy(() => import('../Views/AboutPageView'))
const GeneralWellness = React.lazy(() => import('../Views/GeneralWellness'));
const SexualWellness = React.lazy(() => import('../Views/SexualWellness'));
const IntimateWellness = React.lazy(() => import('../Views/IntimateWellness'));
const ProductView = React.lazy(() => import('../Views/ProductsView'));
const ProductPageView = React.lazy(() => import('../Views/ProductPageView'));
const Faq = React.lazy(() => import('../Views/Faq'));
const ContactUs = React.lazy(() => import('../Views/ContactUs'));
const TermsAndConditons = React.lazy(() => import('../Views/TermsAndCondtions'));
const PrivacyPolicy = React.lazy(() => import('../Views/PrivacyPolicy'));
const RefundPolicy = React.lazy(() => import('../Views/RefundPolicy'));
const ShippingPolicy = React.lazy(() => import('../Views/ShippingPolicy'));

const RoutesData = [
    { path: '/', element: HomePageView, name: 'Home page' },
    { path: '/about', element: AboutPageView, name: 'About page' },
    { path: '/general-wellness', element: GeneralWellness, name: 'General Wellness page' },
    { path: '/sexual-wellness', element: SexualWellness, name: 'Sexual Wellness page' },
    { path: '/intimate-menstrual-wellness', element: IntimateWellness, name: 'Sexual Wellness page' },
    { path: '/products', element: ProductView, name: 'Products page' },
    { path: '/product/view/:productId', element: ProductPageView, name: 'Products page' },
    { path: '/faq', element: Faq, name: 'Faq page' },
    { path: '/contact', element: ContactUs, name: 'Contact page' },
    { path: '/terms-and-condtions', element: TermsAndConditons, name: 'Terms and conditions page' },
    { path: '/privacy-policy', element: PrivacyPolicy, name: 'Privacy policy page' },
    { path: '/refund-policy', element: RefundPolicy, name: 'Refund policy page' },
    { path: '/shipping-policy', element: ShippingPolicy, name: 'Shipping policy page' },
]

export default RoutesData;