import React from 'react'

function TopBar() {
    return (
        <div className='top-bar-dark'>
            <p>IKDK Eco-conscious wellness at your fingertips</p>
        </div>
    )
}

export default TopBar
