import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import RoutesData from './Routes/Routes';
import TopBar from './Components/TopBar/TopBar';
import NavBar from './Components/NavBar/NavBar';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Components/ScrollTop/ScrollTop';

function App() {
  return (
    <div className="App">
      <Router basename='/'>
        <div className='top-sec'>
          <TopBar />
        </div>
        <div className='navbar'>
          <NavBar />
        </div>
        <Suspense>
          <ScrollToTop />
          <Routes>
            {RoutesData.map((data, index) => (
              <Route
                key={index + 1}
                path={data.path}
                name={data.name}
                element={<data.element />}
              />
            ))}
          </Routes>
        </Suspense>
        <div className='footer'>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
