import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Assets from '../../Assets/Assets';
import { Link, useNavigate } from 'react-router-dom';

function NavBar() {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    const [scrollPosition, setScrollPosition] = useState();

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div className='navbar-sec'>
            <Navbar expand="md" className="bg-body-tertiary" fixed={scrollPosition > 200 ? 'top' : ''}>
                {/* <Container> */}
                <Navbar.Brand><Link to='/'><img src={Assets.logo} alt="Logo" /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavDropdown onClick={showDropdown} title={<div><Link to='/products'>Shop Now</Link><p className='menu-add'>{show == false ? '+' : '-'}</p></div>} id="basic-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                            <NavDropdown.Item><Link to='/general-wellness'>General Wellness</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/sexual-wellness'>Sexual Wellness</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to='/intimate-menstrual-wellness'>Intimate &amp; Menstrual Care</Link></NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link href="#home">Let's Talk</Nav.Link> */}
                        <Nav.Link><Link to='/about'>About Us</Link></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div className='new-left'></div>
                {/* </Container> */}
            </Navbar>
        </div >
    )
}

export default NavBar
