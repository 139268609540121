import React from 'react';
import Assets from '../../Assets/Assets';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className='footer-sec px-5 pt-5 pb-3'>
            <div className='row'>
                <div className='logo-sec'>
                    <img src={Assets.logo} alt="logo" />
                </div>
                <div className='link-sec'>
                    <p><Link to='/'>Home</Link></p>
                    <p><Link to='/products'>Products</Link></p>
                    <p><Link to='/about'>About Us</Link></p>
                    {/* <p to='/about'>Refer a Friend</p> */}
                    <p><Link to='/faq'>Faq</Link></p>
                </div>
                <div className='link-sec'>
                    {/* <p to='/about'>Let's Talk</p> */}
                    <p><Link to='/contact'>Contact Us</Link></p>
                    <p to='/about'><Link to='/terms-and-condtions'>Terms Of Services</Link></p>
                    <p to='/about'><Link to='/privacy-policy'>Privacy Policy</Link></p>
                    <p to='/about'><Link to='/refund-policy'>Return and Refund Policy</Link></p>
                    <p to='/about'><Link to='/shipping-policy'>Shipping Policy</Link></p>
                </div>
                <div className='address-sec'>
                    <p>IKDK3012 Private Limited .</p>
                    <p>Plus Offices-1st Floor Landmark Cyber Park, Sector 67,Gurugram, Haryana-122018</p>
                    <p>E-mail: <a href="mailto:sak@ikdk.co.in">sak@ikdk.co.in</a></p>
                    <p>Reach us at <a href="tel:+91-9651799888">+91-9651799888</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer
